* {
  margin: 0;
}

:root {
  --blue: #f27941;
  --light-blue: #ffcf75;
  --primary-grey: #0f0f0d;
  --secondary-grey: #17181c;
  --blackish: #0c0c0d;
}

::selection {
  background: var(--light-blue);
  color: black;
}

body {
  margin: 0;
  font-family: 'Inter', Arial;
}

.nice-btn {
  border: none;
  width: 100%;
  font-size: 15px;
  cursor: pointer;
  padding: 12px 15px;
  display: inline-block;
  margin: 13px 0 0 0;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
  outline: none;
  position: relative;
  border-radius: 4px;
  background: #2e2d2d;
  color: #fff;
  box-shadow: 0 6px #1d1d1d;
}

.nice-btn:hover {
  background: #333;
}

.nice-btn:active {
  box-shadow: 0 0 #000;
  top: 6px;
}

.section {
  width: 100%;
  color: white;
  font-size: 32px;
  padding: 50px 40px;
  box-sizing: border-box;
}

body .section:nth-child(2n + 1) {
  background: var(--primary-grey);
}

body .section:nth-child(2n) {
  background: var(--secondary-grey);
}

@media(max-width: 1000px) {
  .section {
    font-size: 28px;
    padding: 40px 25px;
  }
}